.form-mailing-list {
  position: relative;
  
  .form__control {
    font-size: map-get($font-sizes, 'xs');

    border-radius: var(--bs-border-radius-lg);

    @include media-breakpoint-up('sm') {
      font-size: map-get($font-sizes, 'sm');
    }

    @include media-breakpoint-up('md') {
      padding-right: 155px;
    }

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'lg');
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up('md') {
      position: absolute;
      top: 9px;
      right: 9px;
      z-index: map-get($zIndex, 'high');

      width: auto;
      height: rem(51px);
      padding: 0.75rem 2rem;
    }
  }
}