.table-schedule {
  table-layout: fixed;

  thead {
    // Visually hide the header
    line-height: 0;
    text-indent: -9999px;

    .table-schedule__time {
      width: 25%;

      @include media-breakpoint-up('sm') {
        width: 15%;
      }
    }

    .table-schedule__event {
      width: 75%;

      @include media-breakpoint-up('sm') {
        width: 85%;
      }
    }
  }

  &__slot {
    border: 1px solid var(--bs-border-color);

    &--speaker {
      background: rgb(0 0 0 / 5%);
    }
  }

  tbody .table-schedule__time,
  tbody .table-schedule__event {
    padding: 1rem;
  }

  tbody &__time {
    text-align: center;

    &--start {
      font-size: larger;
      font-weight: bold;
    }

    & > span {
      display: block;
    }
  }

  tbody .table-schedule__event {
    border-left: 1px solid var(--bs-border-color);
    &--subtitle {
      display: block;
      font-size: 80%;
    }
  }
}
