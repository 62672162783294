.list-social-platform {
  display: flex;
  margin: 0;

  &__item + &__item {
    margin-left: $spacer;
  }

  &__link {
    @include transition(color $base-animation-duration);

    color: $base-font-color;

    &:hover,
    &:active {
      color: map-get($color-gray, 'light');
      text-decoration: none;
    }
  }
}
