@use '../variables';

.list-speaker {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  padding-left: 0;

  text-align: center;

  @include media-breakpoint-up('sm') {
    grid-template-columns: repeat(4, 1fr);
  }

  &__individual {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__link {
    display: block;
  }

  &__photo {
    max-width: 75%;
    border-radius:50%;
  }

  &__name {
    margin-block-start: 1rem;

    font-size: map-get(variables.$font-sizes, 'lg');
    line-height: 1.4;

    @include media-breakpoint-up('sm') {
      font-size: map-get(variables.$font-sizes, 'xl');
    }
  }

  &__social {
    &-icon.icon {
      width: calc(2rem * 0.75);
    }

    &-link {
      text-decoration: none;
    }

    &-link:not(:hover) {
      color: variables.$base-font-color;
    }
  }

  &__bio {
    margin-top: 1rem;

    &--mini {
      margin-top: 0.6rem;
    }
  }
}

.list-speaker--with-info {
  grid-template-columns: 1fr;

  @include media-breakpoint-up('sm') {
    grid-template-columns: repeat(2, 1fr);
  }
}
