.app-hero {
  display: flex;
  align-items: center;
  padding: 2rem 0;

  background-color: $primary;

  &__heading {
    font-weight: 500;
  }

  &__subheading {
    font-size: map-get($font-sizes, 'lg');
  }

  &__message {
    margin-block: 0.75rem 0;

    color: $white;
    background-color: $primary;

    @include media-breakpoint-up(md) {
      align-self: flex-start;
      margin-top: 1.5rem;
    }
  }

  &,
  a {
    color: $white;
  }
}
