.section-text {
  ul {
    list-style-type: disc;
  }

  ol, ul {
    li + li {
      margin-top: 1rem;
    }
  }
}
