.btn {
  &--primary {
    @extend .btn-primary;

    color: $white;
    text-transform: uppercase;

    &,
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      --bs-btn-hover-border-color: map-get($color-gray, 'primary');

      border: 2px solid map-get($color-brand, 'primary');
      border-radius: var(--bs-border-radius-lg);
      box-shadow: none;
    }
  }

  &--secondary {
    @extend .btn-secondary;
  }

  &--link {
    @extend .btn-link;
  }

  &--sm {
    @extend .btn-sm;
  }

  &--lg {
    @extend .btn-lg;
  }

  &--outline-light {
    @extend .btn-outline-light;
  }
}
