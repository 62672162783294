@use '../variables';

.list-sponsor {
  @include make-row();

  &__company {
    @include make-col-ready();
    @include make-col(6);

    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up('sm') {
      @include make-col(4);
    }
  }

  &__link {
    display: block;
    width:100%;
  }

  &__logo {
    width: 100%;
    max-height: 100px;

    @include media-breakpoint-up('sm') {
      max-height: 150px;
    }
  }

  & + & {
    @include media-breakpoint-up('sm') {
      margin-top: 1rem;
    }
  }

  & + &:not(:last-child) {
    margin-bottom: 1rem;

    @include media-breakpoint-up('sm') {
      margin-bottom: 2rem;
    }
  }
}

.list-sponsor--with-info {

  .list-sponsor__company {
    @include make-row();
    @include make-col(12);

    align-items: center;

    &:nth-child(odd) {
      background: rgb(0 0 0 / 5%);
    }
  }

  .list-sponsor__link,
  .list-sponsor__info {
    @include make-col-ready();
  }

  .list-sponsor__link {
    @include make-col(12);

    @include media-breakpoint-up('sm') {
      @include make-col(4);
    }
  }

  .list-sponsor__info {
    @include make-col(12);

    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up('sm') {
      @include make-col(8);

      text-align: left;
    }
  }

  &.list-sponsor--gold {
    .list-sponsor__company {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &.list-sponsor--silver,&.list-sponsor--speaker {
    .list-sponsor__info {
      @include media-breakpoint-up('sm') {
        font-size: map-get(variables.$font-sizes, 'sm');
      }
    }
  }
}
