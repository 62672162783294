.app-footer__social-platform {
  padding-block: 1rem;
  text-align: center;

  @include media-breakpoint-up('sm') {
    padding-block: 2.5rem;
  }

  .list-social-platform {
    display: flex;
    justify-content: center;
  }
}

.app-footer__footnote {
  padding-block: 1rem 2rem;

  .container {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up('sm') {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .app-footer__community {
    display: none;
    text-align: center;

    @include media-breakpoint-up('sm') {
      display: initial;
    }
  }

  .app-footer__link {
    text-align: right;
  }
}
