body.home .app-hero {
  align-items: flex-start;

  color: $base-font-color;
  text-align: center;

  background: $white;

  @include media-breakpoint-up('sm') {
    margin-bottom: 4rem;

    text-align: left;
  }

  .container {
    height: rem(400px);

    background: {
      image: url('/images/home/elephant-illustration.png');
      repeat: no-repeat;
      position: bottom center;
      size: contain;
    }

    @include media-breakpoint-up('sm') {
      display: flex;
      flex-direction: column;
      justify-content: center;

      background: {
        position: bottom right;
      }
    }
  }

  &__subheading {
    display: block;
  }

  &__text {
    background: $white;
  }

  &__action {
    align-self: center;
    margin-top: 2rem;

    @include media-breakpoint-up('sm') {
      align-self: flex-start;
    }
  }
}

body.home .app-content {

  text-align: center;

  h2 {
    border: none;
  }
}

body.home .speaker-lineup {

  .list-speaker {
    @include media-breakpoint-up('md') {
      grid-template-columns: repeat(4, 1fr);
    }

    &__photo {
      max-width: 50%;
    }
  }
}

body.home .sponsors {

  .list-sponsor {
    justify-content: center;
    padding-left: 0;

    @include media-breakpoint-up('sm') {
      margin-inline: auto;
    }

    &--silver {
      @include media-breakpoint-up('md') {
        width: 75%
      }

      .list-sponsor__company {
        @include make-col(4);
      }
    }
  }

  .btn {
    width: 80%;

    @include media-breakpoint-up('sm') {
      width: auto;
    }
  }
}
