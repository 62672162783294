.nav-tab {
  display: flex;

  &__link {
    display: block;
    padding: 0.5rem 1rem;

    text-decoration: none;

    border-radius: var(--bs-border-radius-lg);
  }

  &--pills &__link--active {
    color: white;
    background: var(--bs-primary);
  }
}
