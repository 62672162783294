@use '../variables';

body.schedule {
  .schedule-browser {
    &__navigation {
      display: flex;
      justify-content: center;

      margin-bottom: 2rem;

      @include media-breakpoint-up('sm') {
        font-size: map-get(variables.$font-sizes, 'xl');
      }
    }

    &__tabpane {
      display: none;

      &--active {
        display: block;
      }
    }
    
    .table-schedule {
      width: 100%;
  
      @include media-breakpoint-up('xl') {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
