@use '../functions/sizing';
@use '../variables';

.btn-toggle {
  display: flex;
  align-items: center;

  width: sizing.rem(42px);
  height: sizing.rem(48px);
  padding: sizing.rem(8px);

  cursor: pointer;

  &__icon {
    display: block;
    position: relative;
    pointer-events: none;

    &,
    &::before,
    &::after {
      width: sizing.rem(26px);
      height: sizing.rem(2px);
      border-radius: 2px;
      background-color: var(--bs-body-color);

      transition-duration: variables.$base-animation-duration;
      transition-timing-function: variables.$base-animation-timing;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
    }

    &::before {
      top: -9px;
      left: 0;
    }

    &::after {
      top: 9px;
      left: 0;
    }
  }

  &:has(input:checked) &__icon {
    width: 0;

    &::before {
      transform: translateY(9px) rotate(45deg);
    }

    &::after {
      transform: translateY(-9px) rotate(-45deg);
    }
  }
}

// Fallback for browsers not supporting the selector :has
// `menu--open` is added via the navigation Stimulus controller 
@supports not selector(:has(a)) {
  body.menu--open .btn-toggle .btn-toggle__icon {
    width: 0;
  }

  body.menu--open .btn-toggle .btn-toggle__icon::before {
    transform: translateY(9px) rotate(45deg);
  }

  body.menu--open .btn-toggle .btn-toggle__icon::after {
    transform: translateY(-9px) rotate(-45deg);
  }
}
