@use '../variables';

.app-navigation {
  position: fixed;
  inset: 0 -100% 0 auto;
  z-index: map-get($zIndex, 'high');

  width: min(calc(100vw - 4rem), 16rem);

  transition: all variables.$base-animation-duration variables.$base-animation-timing;
  background: var(--bs-white);
  box-shadow: 0 0 3rem rgb(0 0 0 / 40%);

  @include media-breakpoint-up('xl') {
    position: revert;
    inset: revert;
    box-shadow: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: calc(var(--bs-gutter-x) / 2);

    padding-top: var(--app-header-height);

    @include media-breakpoint-up('xl') {
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 0;
      padding-top: 0;
    }

    @include media-breakpoint-up('xxl') {
      gap: var(--bs-gutter-x);
    }
  }

  &__item {
    display: block;
  }

  &__link {
    isolation: isolate;
    position: relative;

    display: flex;
    align-items: center;
    padding-left: 2rem;

    height: 2.5rem;

    color: var(--bs-body-color);
    font-size: 1rem;
    line-height: 1.75;
    font-weight: 500;

    @include media-breakpoint-up('xl') {
      display: block;
      padding: 0 0.75rem;

      height: auto;
    }
  }

  &__link::before {
    content: '';

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: map-get($zIndex, 'behind');

    width: 75%;
    height: 75%;

    opacity: 0;
    background-color: $black;
    border-radius: 1rem;
    transition: all variables.$base-animation-duration variables.$base-animation-timing;
  }

  &__link:hover {
    @include media-breakpoint-up('xl') {
      color: var(--bs-white);
    }
  }

  &__link:hover::before {
    width: 100%;
    height: 100%;

    @include media-breakpoint-up('xl') {
      opacity: 1;
    }
  }

  &__text {
    white-space: pre;
  }
}
