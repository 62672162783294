@use '../variables';

body.speaker .list-speaker {
  gap: 2rem;

  &__individual {
    padding-inline: 1rem;
    background: rgb(0 0 0 / 5%);

    @include media-breakpoint-up('sm') {
      padding-inline: 2rem;
    }
  }

  &__photo {
    max-width: 25%;
  }
}
