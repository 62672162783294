@use '../functions/sizing';
@use '../variables';

.card-talk {
  &__title {
    margin-bottom: 0.5rem;

    @include media-breakpoint-up('sm') {
      font-size: map-get(variables.$font-sizes, 'xl');
    }

    > a:not(:hover) {
      color: variables.$base-font-color;
      text-decoration: none;
    }
  }

  &__speaker {
    display: flex;
  }

  &__profile {
    margin: 0;

    width: sizing.rem(60px);
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__photo {
    max-width: 100%;
    border-radius: 50%;
  }

  &__profile + &__info {
    padding-left: 0.5rem;
  }

  &__cancellation {
    margin-top: 1rem;
    font-size: smaller;
  }

  &__social {
    &-icon.icon {
      width: calc(2rem * 0.65);
    }

    &-link {
      text-decoration: none;
    }

    &-link:not(:hover) {
      color: variables.$base-font-color;
    }
  }

  // For dual speaker talks
  & + & {
    margin-top: 0.5rem;
  }
}
