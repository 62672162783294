@use '../functions/sizing';

.logo {
  display: block;

  background: {
    repeat: no-repeat;
    size: contain;
  }

  &--icon {
    width: sizing.rem(71px);
    height: sizing.rem(50px);

    background: {
      image: url('/images/shared/logo/logo-icon.svg')
    };
  }

  &--text {
    width: sizing.rem(179px);
    height: sizing.rem(26px);

    background: {
      image: url('/images/shared/logo/logo-text.svg')
    };
  }

  &--text-icon {
    width: sizing.rem(223px);
    height: sizing.rem(26px);

    background: {
      image: url('/images/shared/logo/logo-text-icon.svg')
    };

    @include media-breakpoint-up('lg') {
      width: sizing.rem(334px);
      height: sizing.rem(40px);
    }
  }
}
