.layout-default {

  // `menu--open` is added via the navigation Stimulus controller 
  body.menu--open {
    overflow: hidden;
  }

  .app-header {
    display: flex;
    align-items: center;

    height: var(--app-header-height);
  }

  .app-hero {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up('md') {
      margin-bottom: 3rem;
    }
  }

  .app-content {
    min-height: rem(400px);
    padding: 1.5rem 0;

    @include media-breakpoint-up('md') {
      padding: 3rem 0;
    }

    section + section {
      margin-top: 2rem;

      @include media-breakpoint-up('sm') {
        margin-top: 4rem;
      }
    }
  }

  .app-hero ~ .app-content {
    padding-top: 0;
  }

  .mailing-list {
    padding: 2rem;

    color: $white;
    text-align: center;

    background-color: black;

    &__text {
      padding: 0 2rem;

      @include media-breakpoint-up('md') {
        padding: 0;
      }
    }

    .form-mailing-list {
      @include media-breakpoint-up('md') {
        @include make-col(6);
        @include make-col-offset(3);
      }
    }
  }
}
