.icon {
  width: 2rem;
  height: 2rem;
  display: inline-block;

  &--small {
    width: 1rem;
    height: 1rem;
  }
}
