.form {
  &__group {
    margin-bottom: rem(14px);

    @include media-breakpoint-up('xl') {
      margin-bottom: rem(26px);
    }
  }

  &__label {
    @include line-height(20px, map-get($font-sizes, 'lg'));

    display: block;

    margin-bottom: rem(12px);

    font-size: map-get($font-sizes, 'lg');
    font-weight: $font-weight-bold;
  }

  &__control {
    @include line-height(28px, map-get($font-sizes, 'xl'));

    display: block;

    width: 100%;
    height: rem(59px);

    padding: rem(14px 18px 13px 18px);

    font-size: map-get($font-sizes, 'lg');
    font-weight: $font-weight-normal;

    background-color: #fff;
    background-clip: padding-box;

    border: 2px solid map-get($color-gray, 'primary');

    &::placeholder {
      color: map-get($color-gray, 'dark');
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: map-get($color-gray, 'light');
      border-color: map-get($color-gray, 'light');
      opacity: 1;
    }

    &--invalid {
      background-color: $white;
      border-color: map-get($color-brand, 'primary');
    }

    &--lg {
      height: rem(69px);
      padding: rem(20px 18px 19px 18px);
    }
  }

  &__text {
    @include line-height(20px, map-get($font-sizes, 'xs'));

    display: block;

    height: rem(20px);
    margin-top: rem(9px);

    font-size: map-get($font-sizes, 'xs');
  }

  &__control--invalid + &__text {
    color: map-get($color-brand, 'primary');
  }
}
